@import "../imports/imports";

body {
  .mat-list-item-content {
    @include media(md) {
      .actions {
        opacity: 0;
        transform: scale(0.6);
        transition: transform 0.2s $easeIn, opacity 0.1s;

        &:hover {
          //transform: scale(1);
        }
      }

      &:hover {
        .actions {
          opacity: 1;
          transform: scale(0.8);
          transition: transform 0.2s $easeOut, opacity 0.1s 0.1s;
        }
      }
    }
  }

  .mat-card, .mat-expansion-panel {
    &:not([class*=mat-elevation-z]) {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%), 0px -2px 0px -1px rgb(0 0 0 / 4%);
      border-radius: 4px;
    }
  }

  .mat-card-title {
    font-weight: $fw-light;
  }

  .mat-card-footer {
    margin: 0;
  }

  .mat-icon {
    &.smaller {
      font-size: 1.5em;
      height: 18px;
      width: 18px;
    }
  }

  .mat-menu-panel {
    max-width: 310px;
  }

  .mat-menu-content {
    .section-header {
      padding: 3px (8px*2);
      display: flex;
      align-items: center;
      font-size: 0.8em;

      .mat-icon {
        font-size: 1.6em;
        line-height: 1em;
        top: 0.12em;
        left: -0.08em;
        margin-right: 0;
        position: relative;
      }
    }

    .mat-menu-item {
      &.active {
        font-weight: 900;
      }
    }
  }

  .mat-list-item {
    &.active:focus, &.active:active, &.active {
      background: transparentize($light-color, 0.8);
    }
  }

  .mat-expansion-panel-header .mat-content {
    align-items: center;
  }

  .mat-expansion-panel .mat-button-toggle-label-content {
    line-height: 28px;
  }

  .mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
    font-size: 16px;
  }

  .mat-nav-list[dense] .mat-list-item .mat-list-item-content {
    padding: 0;
  }

  .mat-tooltip {
    color: $dark-primary-text;
    background: $light-color;
    box-shadow: $box-shadow;
    font-size: 0.8em;
  }

  .mat-step-icon .mat-icon {
    color: $light-color;
  }

  .mat-menu-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mat-expansion-panel.p-0 > .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0;
    overflow: hidden;

    .mat-expansion-panel.mat-expanded {
      box-shadow: none;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: -5%;
        top: 0;
        width: 110%;
        height: 100%;
        pointer-events: none;
        z-index: 0;
        box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.14), inset 0 3px 1px -2px rgba(0,0,0,.2), inset 0 1px 5px 0 rgba(0,0,0,.12);
      }

      &:not(:last-of-type) {
        &:after {
          box-shadow: inset 0 2px 2px 0 rgba(0,0,0,.14), inset 0 3px 1px -2px rgba(0,0,0,.2), inset 0 1px 5px 0 rgba(0,0,0,.12), inset 0 -2px 5px 0 rgba(0,0,0,0.04), inset 0 -3px 2px -1px rgba(0,0,0,0.15);
        }
      }
    }
  }

  .mat-badge {
    &.no-text {
      .mat-badge-content {
        text-indent: -999px;
        overflow: hidden;
      }
    }

    &.pulse.mat-badge-warn {
      .mat-badge-content {
        box-shadow: 0 0 0 transparentize($warn-color, 0.6);
        animation: $animation-pulse-warn-default;
      }
    }

    &.mat-badge-xs {
      .mat-badge-content {
        width: 10px;
        height: 10px;
      }
    }
  }

  .mat-slide-toggle.mat-checked.mat-primary:not(.mat-disabled) .mat-slide-toggle-bar,
  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background,
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    @include embedded-color-scheme();
  }

  .mat-step-header {
    .mat-step-label-selected {
      @include embedded-color-scheme(color);
    }
  }

  .mat-list-base .mat-list-item {
    color: $text-color;
  }

  .mat-icon.mat-primary {
    @include embedded-color-scheme(color);
  }

  .mat-icon.mat-success, .mat-icon.mat-green {
    color: $success-color !important;
  }
}
