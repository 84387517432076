@import "../../imports/imports";

.list-view {
  .star {
    font-size: 0.8em;
    color: $grey-color;
  }

  .mat-list-item {
    &.no-icons .mat-list-item-content {
      padding-left: 8px;
    }

    .mat-nav-list a {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .archive.mat-nav-list[dense] {
    .mat-list-item .mat-list-item-content {
      transition: padding-left 0.4s $easeOutExpo;
      padding-left: 8px;

      &:hover {
      }
    }
  }
}
