@mixin background-image-retina($file, $type, $width: contain, $height: false) {
  background-image: url(($file + '.' + $type));

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url(($file + '@2x.' + $type));

    @if($height) {
      background-size: $width $height;
    }
    @else {
      background-size: $width;
    }
  }
}

@mixin media($key, $size: map-get($grid-breakpoints, $key)) {
  @media only screen and (min-width: #{$size}) {
    @content;
  }
}

@mixin media-below($key, $size: map-get($grid-breakpoints, $key)) {
  @media only screen and (max-width: #{$size}) {
    @content;
  }
}

@mixin color-scheme($prop: background) {
  @each $area, $color in $color-map {
    &.#{$area} {
      #{$prop}: $color;
    }
  }
}

@mixin embedded-color-scheme($prop: background-color, $transparency: 0) {
  @each $area, $color in $color-map {
    .#{$area} & {
      #{$prop}: transparentize($color, $transparency) !important;
    }
  }
}


@mixin extended-bg($file, $color-1, $color-2) {
  @include filter-gradient($color-1, $color-2, horizontal); // IE6-9 fallback on horizontal gradient
  @include background-image(linear-gradient(90deg, $color-1 20%, $color-2 80%));
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: url(#{$file}) no-repeat center center;
    background-size: auto 140%;

    @include media(lg) {
      background-size: auto 100%;
    }
  }
}

@mixin flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin placeholder($color: $grey-lighter, $opacity: 0.8) {
  &::placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin circle($width: 30px, $height: $width) {
  width: $width;
  height: $height;
  display: block;
  border-radius: 50%;
}

@mixin size( $width, $height: $width ) {
  @if _is-size($height) {
    height: $height;
  }
  @else {
    @error "`#{$height}` is not a valid length for the `$height` argument " + "in the `size` mixin.";
  }

  @if _is-size($width) {
    width: $width;
  }
  @else {
    @error "`#{$width}` is not a valid length for the `$width` argument " + "in the `size` mixin.";
  }
}


/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin material-icon() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
