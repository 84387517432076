@import "../imports/imports";

$mat-tab-height: 40px;

body {

  .mat-tab-links {
    justify-content: space-around;

    @include media(md) {
      justify-content: flex-start;
    }
  }

  .mat-tab-link {
    min-width: 60px;

    @include media(md) {
      min-width: 160px;
    }
  }

  .mat-tab-link {
    opacity: 1;
  }

  .mat-tabs-vertical {
    @include media(md) {
      border-bottom: none !important;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      max-width: 125px;
      position: fixed;
      height: 100%;
      margin-top: $main-padding;

      .mat-tab-links {
        flex-direction: column;

        .mat-tab-link {
          text-align: left;
          max-width: 100%;
          min-width: 0;
          line-height: $mat-tab-height;
          height: $mat-tab-height;
          justify-content: flex-end;

          .mat-badge-small .mat-badge-content {
            top: 4px;
          }

          .mat-badge-xs .mat-badge-content {
            top: 10px;
            right: -10px;
          }
        }
      }

      .mat-ink-bar {
        width: 2px !important;
        height: $mat-tab-height;
        bottom: auto;
        left: auto !important;
        right: 0;
      }

      @for $i from 0 through 15 {
        &.active-#{$i} {
          .mat-ink-bar {
            top: $mat-tab-height * $i !important;
          }
        }
      }
    }
  }

}
.mat-ink-bar {
  @include embedded-color-scheme();
}
