@import "../../imports/imports";

.question-preview {
  border: $default-border #e1e1e1;
  padding: calc($main-padding / 2);
  position: relative;
  margin-top: 20px;

  &:before {
    content: 'Preview';
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 0.75em;
    color: $grey-medium-color;
  }
}
