@import "../imports/imports";

.header {
}

.mat-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: transparentize($light-color, 0);

  .mat-sidenav-content & {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .section-name {
      @include media(sm) {
        margin: 0;
        padding-top: 15px;
        padding-left: $main-padding;
      }
    }

    .menu-button {
      padding: 0 8px;
      position: relative;

      .mat-icon {
        top: 3px;
        position: relative;
      }

      @include media(sm) {
        padding: 0 8px*2;
      }
    }
  }

  .user-nav {
    @include media(sm) {
    }

    @include media(md) {
      margin-top: 2px;
    }

    .mat-button {
      min-width: 0;
      transition: padding 0.1s $easeOut;
      padding: 0 4px;

      @include media(sm) {
        padding: 0 16px;
      }

      @include media(md) {
        //border-right: 1px solid transparentize($light-color, 0.6);
        border-radius: 0 0 4px 4px;
        //margin-right: 8px;

        .mat-button-focus-overlay {
          display: block;
        }
      }
    }

    .mat-icon {
      left: 0;
      font-size: 2em;
      width: 34px;
      height: 34px;
      line-height: 34px;

      @include media(sm) {
        left: 0;
      }

      @include media(md) {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 1.6em;
      }
    }
  }

  .header-bar {
    height: auto;
    padding-top: 3px;

    @include media(sm) {
      padding-top: 6px;
    }

    @include media(md) {
      padding-top: 0;
    }

    .button-wrapper {
      padding-top: 2px;

      @include media(sm) {
        position: relative;
        right: 21px;
        padding-top: 0;
      }

      @include media(md) {
        right: $main-padding;
        background: $darker-color;
        color: $light-color;
        border-radius: 0 0 4px 4px;
        padding-bottom: 2px;
        box-shadow: $global-menu-shadow;
      }
    }
  }
}

ul.action-nav {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;

  .mat-fab {
    color: $light-color;

    .mat-icon {
      color: $light-color;
    }
  }

  @include media(md) {
    position: static;
    z-index: auto;
    padding-right: $main-padding;
  }

  .mat-button, .mat-raised-button, .mat-flat-button {
    min-width: 0;
    transition: padding 0.1s $easeOut;

    @include media(md) {
      padding: 0 0 0 8px*2;

      &:hover, &:focus, &:active {
        padding: 0 8px*2;
        background: rgba(0,0,0,.12);
      }

      &:active {
        background: rgba(0,0,0,.22);
      }
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.help-button {
  .mat-icon {
    margin-top: 5px;
  }
}
