@import "../imports/imports";

.local-nav {
  .local-nav-button {
    position: absolute;
    left: 40px;
    top: 7px;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 20px;

    .mat-icon {
      left: 2px;
      top: 2px;
      font-size: 30px;
    }

    @include media(sm) {
      left: 30px;
      top: 5px;
      line-height: 50px;
    }

    .inner-text {
      font-size: 24px;

      @include media(sm) {
        font-size: 28px;
      }
    }
  }

  &.bb-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-tab-nav-bar, .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-link {
    min-width: 0;
    padding: 0 $main-padding-m;

    @include media(md) {
      padding: 0 $main-padding;

      &.mat-tab-label-active {
        color: $primary-color;

        @each $section, $color in $color-map {
          .#{$section} & {
            color: $color !important;
          }
        }
      }
    }
  }
}
