@import '../../imports/imports';

.samples {
  .chart-wrapper {
    .label {
      margin-top: -17px;
      margin-left: -45px;
      width: 90px;
    }
  }

  .list-view .inner-content.sm {
    max-width: 700px;
  }
}

$action-column-width: 160px;

.sample-details {
  .mat-tabs-vertical {
    @include media(md) {
      max-width: $action-column-width;
      width: $action-column-width;
    }
  }

  .back-button {
    left: 20px;
    position: absolute;

    @include media(md) {
      position: fixed;
      left: 175px;
    }
  }
}

.main-content .inner-content {
  .content.sample-content {
    @include media(md) {
      width: calc(100% - #{$action-column-width + 1});
      margin-left: $action-column-width + 1;
    }

    .action-column {
      @include media(md) {
        width: $action-column-width;
      }
    }
  }
}

.sample-profiles {
  .mat-card.actionable-card {
    width: 100%;

    .mat-card-title {
      border: none;
      position: relative;
      padding: 0.9rem $card-side-wide-padding;
    }

    .mat-card-content {
      min-height: 0;
      font-size: 0.8em;
      padding: $card-side-wide-padding $card-side-wide-padding 1.5rem;
    }

    .mat-card-footer {
      padding: 8px $card-side-wide-padding;
      border-top: 1px solid $card-border-color;

      .mat-flat-button {
        .mat-icon {
          line-height: 21px;
          margin-right: 2px;
        }
      }
    }

    .bar-graph {
      position: absolute;
      left: 0;
      bottom: -4px;
      margin-bottom: 0;
    }
  }

  .mat-flat-button.loading-button {
    .mat-icon {
      left: -2px;
      top: -1px;
    }
  }
}

.sample-filters-overview, .sample-profiles .filters {
  transition: all 0.2s $easeOutExpo;
  padding: 0 10px 0;
  margin: 1.5rem -10px;

  .filters-short {
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-left: -9px;

    .mat-icon {
      transition: transform 0.3s $easeOutExpo;
      height: 20px;
      margin-top: -3px;
    }
  }

  &.expanded {
    background: $card-color;
    padding: 10px 10px 20px;

    .filters-short {
      padding: 0 0 8px;

      .mat-icon {
        transform: rotate(45deg);
        line-height: 19px;
        height: 19px;
        margin-top: 0;
      }
    }
  }

  ul {
    li {
      .mat-icon {
        font-size: 1.6em;
        text-align: right;
      }
    }
  }

  .block-with-text {
    &:after {
      background: $card-color;
    }
  }

  .mat-expansion-panel-header {
    height: 32px !important;

    .mat-expansion-indicator {
      position: relative;
      top: -3px;
    }

    &.mat-expanded {
      height: 48px !important;
    }
  }

  .distribution-cell & {
    margin:0.7rem -10px;
    &.expanded {
      padding: 0 10px;
    }
    li {
      font-size: 0.8em;
    }
  }
}

.bar-graph {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  background: $grey-light-color;

  .bar {
    height: 4px;
    width: 100%;
    transition: opacity 0.2s;
  }

  &:hover {
    .bar {
      opacity: 0.7;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &.collapsed {
    height: 4px;
    display: flex;
    justify-content: flex-start;

    .bar {
    }
  }
}

.sample-stats {
  .dashboard-panel {
    .stat-pair-box {
      @include media(md) {
        max-width: 250px;
      }

      .stat-pair {
        padding: 20px 20px 10px;
        margin: 0 auto;
      }
    }
  }
}

.sample-filters {
  .mat-expansion-panel-header {
    min-height: 80px;
    height: auto !important;

    @include media(md) {
      min-height: 0;
      height: 54px !important;

      &.mat-expanded {
        height: 64px !important;
      }
    }
  }
}
