@import "../../imports/imports";

$dialog-background: transparentize($light-color, 0);
$dialog-footer-height: 80px;
$dialog-footer-shadow: 30px;
$dialog-title-height: 100px;
$dialog-title-margin: 50px;
$dialog-width: 800px;

%dialog-position {
  left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @include media(md) {
    width: $dialog-width;
    left: 50%;
    margin-left: calc($dialog-width / -2);
  }
}

.mat-dialog-container {

  body & {
    box-sizing: border-box;
    background: none;
    box-shadow: none;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    max-height: 100vh;
    width: 100vw;
  }

  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: transparent;
  }

  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: transparent;
  }

  .dialog-inner {
    opacity: 0;
    height: 100%;
    transition: opacity 0.3s $easeOut;

    .cdk-global-overlay-wrapper & {
      opacity: 1;
    }
  }

  .dialog-close {
    position: fixed;
    top: 0px;
    right: 0;
    opacity: 0;
    z-index: 100;
    transform: scale(1.7);
    transition: opacity 0.3s $easeOut 0.5s;
    padding: 15px 5px 0 0;
    border-radius: 50% 0 35% 50%;
    box-sizing: content-box;

    .cdk-global-overlay-wrapper & {
      opacity: 1;
    }

    &:hover {
      background: $grey-light-color;
    }
  }

  .mat-dialog-title {
    position: fixed;
    top: 0;
    height: $dialog-title-height;
    padding: 20px 50px 20px $main-padding-m;
    z-index: 2;
    font-size: 1.5em;
    line-height: 1.1em;
    background: $dialog-background;
    background: -moz-linear-gradient(top, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 70%,transparentize($dialog-background,1) 100%);
    background: -webkit-linear-gradient(top, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 70%,transparentize($dialog-background,1) 100%);
    background: linear-gradient(to bottom, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 70%,transparentize($dialog-background,1) 100%);
    @extend %dialog-position;

    @include media(sm) {
      padding: 40px $main-padding 0;
      z-index: 4;
      background: -moz-linear-gradient(top, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 80%,transparentize($dialog-background,1) 100%);
      background: -webkit-linear-gradient(top, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 80%,transparentize($dialog-background,1) 100%);
      background: linear-gradient(to bottom, transparentize($dialog-background,0) 0%,transparentize($dialog-background,0) 80%,transparentize($dialog-background,1) 100%);
      text-align: center;
      margin-top: $dialog-title-margin;
      font-size: 2em;
      //line-height: $dialog-title-height;
      height: auto;
      min-height: 120px;
      padding: 0 $main-padding;
    }

    @include media(md) {
      font-size: 3em;
    }

    .text-tiny {
      @include media(md) {
        line-height: 1.3em;
        padding-top: 20px;
        height: 120px;
      }
    }
  }

  .mat-dialog-content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    position: absolute;
    top: $dialog-title-height;
    bottom: $dialog-footer-height;
    z-index: 3;
    padding: $dialog-footer-shadow (2 * $main-padding-m);
    @extend %dialog-position;

    @include media(md) {
      padding: $dialog-footer-shadow $main-padding;
    }
  }

  .mat-dialog-actions {
    position: fixed;
    z-index: 4;
    bottom: 0;
    height: $dialog-footer-height + $dialog-footer-shadow;
    box-sizing: border-box;
    padding: 40px $main-padding-m 0;
    background: $dialog-background;
    background: -moz-linear-gradient(top, transparentize($dialog-background,1) 0%, transparentize($dialog-background,0) 30%, transparentize($dialog-background,0) 100%);
    background: -webkit-linear-gradient(top, transparentize($dialog-background,1) 0%,transparentize($dialog-background,0) 30%,transparentize($dialog-background,0) 100%);
    background: linear-gradient(to bottom, transparentize($dialog-background,1) 0%,transparentize($dialog-background,0) 30%,transparentize($dialog-background,0) 100%);
    @extend %dialog-position;

    @include media(sm) {
      padding: 40px $main-padding 0;
      background: -moz-linear-gradient(top, transparentize($dialog-background,1) 0%,transparentize($dialog-background,0) 20%,transparentize($dialog-background,0) 100%);
      background: -webkit-linear-gradient(top, transparentize($dialog-background,1) 0%,transparentize($dialog-background,0) 20%,transparentize($dialog-background,0) 100%);
      background: linear-gradient(to bottom, transparentize($dialog-background,1) 0%,transparentize($dialog-background,0) 20%,transparentize($dialog-background,0) 100%);
    }
  }

  @include media(sm) {
    $dialog-footer-height: 100px;

    .mat-dialog-content {
      top: $dialog-title-margin + $dialog-title-height;
      bottom: $dialog-footer-height;
    }

    .mat-dialog-actions {
      height: $dialog-footer-height + $dialog-footer-shadow;
    }
  }

  @include media(lg) {
    $dialog-footer-height: 120px;

    .mat-dialog-content {
      bottom: $dialog-footer-height;
    }

    .mat-dialog-actions {
      height: $dialog-footer-height + $dialog-footer-shadow;
    }
  }

  .user-experience {
    margin: 0 auto;
    padding-left: 30px !important;

    @include media(md) {
      padding-left: 0 !important;
      display: flex !important;
    }
  }
}

.cdk-overlay-pane.app-dialog-wrapper {
  width: 100%;

  @include media-below(md) {
    max-width: 100% !important;
  }

  &.drawer {
    right: 0;
    position: fixed !important;
    background: $dialog-background;
    height: 100%;
    top: 0;
    bottom: 0;
    max-width: 800px;

    .mat-dialog-actions {
      width: 100%;
      max-width: 800px;

      @include media(sm) {
        width: 60%;
      }

      @include media(md) {
        width: 30%;
      }
    }

    .dialog-close {
      position: absolute;
    }

    .mat-dialog-container {
      margin: 0;
      padding: 0;

      .mat-dialog-content, .mat-dialog-title {
        width:100%;
        max-width:100%;
      }

      .dialog-inner {
        padding: 0 16px;
      }

      .mat-dialog-content {
        left: 0;
        padding: 0 20px;
        margin: 0 auto;
      }

      .mat-dialog-title {
        position: static;
        margin: 0 auto;
        font-size: 2em;
        padding: 20px 30px 25px 0;
        text-align: left;
        line-height: 40px;
      }
    }

    .mat-dialog-actions {
      position: fixed;
      margin: 0;
      padding: 10px 0 0;
      height: 60px;
      right: 0;
      left: auto;
      background: white;
      box-sizing: content-box;
      justify-content: center;
    }

    .mat-dialog-actions {
        width: 100%;
        max-width: 800px;

        @include media(sm) {
          width: 60%;
        }

        @include media(md) {
          width: 30%;
        }
      }

    @include media(md) {
      &.wide {
        &, .mat-dialog-actions {
          width: 60%;
        }
      }
    }

  }
}

.cdk-overlay-backdrop, .cdk-overlay-dark-backdrop {
  background: $dialog-background;

  &.drawer {
    background: transparentize($dark-color, 0.8);
  }
}

.alert-dialog {
  .mat-dialog-container {
    
  }
}

.short {

  .mat-dialog-container {

    .mat-dialog-title {
      padding: 25px 70px 0;
      line-height: 1.4em;
      text-align: center;
    }

    .mat-dialog-actions, .btn-row {
      padding-top: 0;
    }

    .mat-dialog-content {
      margin: 0 auto;
      padding: 0;

      .inner {
        padding: 40px 30px;
      }
    }
  }

  @include media(md) {
    .dialog-inner {
      z-index: 2;
      position: relative;
    }

    .mat-dialog-container {
      top: -100px;
      position: relative;

      .mat-dialog-title {
        position: static;
        margin: 0 auto;
        min-height: 0;
        font-size: 2rem;
      }

      .mat-dialog-actions, .btn-row {
        height: auto;
        position: static;
        margin: 0 auto;
      }

      .mat-dialog-content {
        max-height: 80vh;
        height: auto;
        overflow-x: auto;
        position: static;
      }
    }
  }
}
