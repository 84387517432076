@import '../../../node_modules/@angular/material/theming';

/*Paths*/
$img-base: "/assets/img/";

/*Fonts*/
$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-body: $font-family-sans-serif;
$font-family-heading: $font-family-sans-serif;

/*Font weights*/
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 600;
$fw-black: 700;
$text-transform: uppercase;

/*Defaults*/
$sides: ((t, top), (l, left), (r, right), (b, bottom));
$default-border: 1px solid;

/*Responsive*/
$grid-breakpoints: (xs: 400px, sm: 600px, md: 960px, ml: 1080px, lg: 1280px, xl: 1550px, xxl: 1920px);

/*Menu defaults*/
$menu-width-m: 80px;
$menu-width: 90px;
$action-menu-width: 27px;
$card-corner-radius: 0.28571429rem;
$card-side-padding: 0.7rem;
$card-side-wide-padding: 1.2rem;
$box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;

$mat-card-padding: 16px;



$main-padding-m: 8px*1;
$main-padding: 8px*5;
