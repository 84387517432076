@import "../../imports/imports";

.mat-card.actionable-card {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  transition: background 0.3s $easeOutExpo;
  margin-bottom: 20px;
  background: $card-color;
  border-radius: $card-corner-radius;
  box-shadow: 0px 1px 3px 0px $card-border-color, 0px 0px 0px 1px $card-border-color !important;

  &:hover {
    background: $light-color;
    border-radius: 0 $card-corner-radius $card-corner-radius $card-corner-radius;
  }

  hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid $card-border-color;
  }

  .mat-card-title {
    margin: 0;
    padding: 0.5rem $card-side-padding;
    color: $brown-color;
    border-bottom: 1px solid $accent-color;

    .actionable-card-title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.flag-warn {
    .mat-card-title {
      border-bottom-color: $warn-color;
    }
  }

  &.flag-amber {
    .mat-card-title {
      border-bottom-color: $yellow-color;
    }
  }

  .mat-card-footer {
    padding: 0.5rem $card-side-padding;
    width: 100%;
    overflow: hidden;

    @include media(sm) {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 0 8px;
    }

    span, .count, b {
      white-space: nowrap;
    }

    .count, b {
      font-size: 0.8em;
      line-height: 120%;
      font-weight: $fw-regular;
    }
  }

  .mat-card-content {
    min-height: 120px;
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    padding: $card-side-padding;

    .mat-chip {
      opacity: 0.8;
    }
  }

  @include media(sm) {
    width: calc(50% - 1.5rem);
    margin-right: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 50px;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  @include media(md) {
    width: calc(33.3333% - 2rem);
    margin-right: 3rem;

    &:nth-child(2n+2) {
      margin-right: 3rem;
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }

  .action-menu-button {
    position: absolute;
    right: 0;
    top: 0;

    .mat-icon {
      padding-top: 3px;
      vertical-align: top;
    }
  }

  .action-menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    background: white;
    box-shadow: none;
    transition: all .1s;
    padding: 3px 0;
    box-shadow: 0px 0px 0px 1px #d4d4d5;
    border-right: 0;
    border-radius: $card-corner-radius 0 0 $card-corner-radius;

    .mat-icon-button, .mat-button-wrapper {
      width: $action-menu-width;
      height: $action-menu-width;
      line-height: 24px;
      display: block;

      .mat-icon {
        font-size: 0.7em;
      }
    }
  }

  @include media(md) {
    .action-card-wrapper:hover &,
    &:hover {
      .action-menu {
        transition: all .6s $easeOutExpo;
        width: $action-menu-width;
        left: -($action-menu-width + 1);
        opacity: 1;
      }
    }
  }

  .mat-flat-button.mat-light {
    background: transparent;
  }
}

.action-card-wrapper {
  display: block;
  padding: 0 2rem;
  margin: 0px -2rem;
}

.mat-list-item {
  .action-menu {
    white-space: nowrap;
    padding: 0;
  }

  &:hover {
    .action-menu {
      opacity: 1;
    }
  }
}


.placeholder-box {
  padding: 20px 10px;
  cursor: pointer;
  opacity: 0.7;
  font-size: 1.5em;
  border: 1px dashed $grey-color;
  background: $card-color;
  border-radius: $card-corner-radius;
  text-align: center;
  transition: opacity 0.2s;

  @include media(md) {
    font-size: 2em;
    padding: 50px 10px;
  }

  &.mat-card {
    min-height: 180px;
    padding: 0 0 20px;
    box-shadow: none !important;
    border-width: 2px;
  }

  &:hover {
    opacity: 1;
  }

  h1 {
    color: $grey-color;
    //font-size: 1.2em;
  }

  .mat-icon {
    font-size: 2.2em;
    width: auto;
    height: auto;
    padding-right: 10px;
    color: $grey-color;
  }
}
