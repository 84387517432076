@use '@angular/material' as mat;
@import 'variables';

$purple-color: #B276B2;
$orange-color: rgb(250, 164, 58);
$blue-color: #5DA5DA;
$gold-color: #B2912F;
$green-color: #60BD68;
$yellow-color: #fbbd08;
$red-color: #F15854;
$brown-color: rgb(123, 111, 98);

$primary-color: $purple-color; //rgb(123, 111, 98);
$secondary-color: $orange-color;
$tertiary-color: $blue-color;
$quaternary-color: $green-color;
$quinary-color: $green-color;
$senary-color: $yellow-color;
$septenary-color: $red-color;

$accent-color: $green-color; //#60bd68;
//$gold-color: rgb(123, 111, 98);
$dark-color: rgb(58, 52, 46);
$darker-color: #3a342e;
$text-color: rgb(123, 111, 98);


$custom-accent-palette: (
  50:   $accent-color,
  100:  $accent-color,
  200:  $accent-color,
  300:  $accent-color,
  400:  $accent-color,
  500:  $accent-color,
  600:  $accent-color,
  700:  $accent-color,
  800:  $accent-color,
  900:  $accent-color,
  A100: $accent-color,
  A200: $accent-color,
  A400: $accent-color,
  A700: $accent-color,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$custom-palette: (
  50:   $primary-color,
  100:  $primary-color,
  200:  $primary-color,
  300:  $primary-color,
  400:  $primary-color,
  500:  $primary-color,
  600:  $primary-color,
  700:  $primary-color,
  800:  $primary-color,
  900:  $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);


$green-palette: (
  50:   $green-color,
  100:  $green-color,
  200:  $green-color,
  300:  $green-color,
  400:  $green-color,
  500:  $green-color,
  600:  $green-color,
  700:  $green-color,
  800:  $green-color,
  900:  $green-color,
  A100: $green-color,
  A200: $green-color,
  A400: $green-color,
  A700: $green-color,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$blue-palette: (
  50:   $blue-color,
  100:  $blue-color,
  200:  $blue-color,
  300:  $blue-color,
  400:  $blue-color,
  500:  $blue-color,
  600:  $blue-color,
  700:  $blue-color,
  800:  $blue-color,
  900:  $blue-color,
  A100: $blue-color,
  A200: $blue-color,
  A400: $blue-color,
  A700: $blue-color,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$red-palette: (
  50:   $red-color,
  100:  $red-color,
  200:  $red-color,
  300:  $red-color,
  400:  $red-color,
  500:  $red-color,
  600:  $red-color,
  700:  $red-color,
  800:  $red-color,
  900:  $red-color,
  A100: $red-color,
  A200: $red-color,
  A400: $red-color,
  A700: $red-color,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-palette: mat.define-palette($custom-palette);
$accent-palette: mat.define-palette($custom-accent-palette); //mat-palette($mat-grey);
$warn-palette: mat.define-palette($red-palette);
$success-palette: mat.define-palette($green-palette);
$info-palette: mat.define-palette($blue-palette);

$accent-color: mat.get-color-from-palette($accent-palette);
$warn-color: $red-color;
$success-color: $green-color;
$info-color: $blue-color;

$light-color: mat.get-color-from-palette(mat.$dark-theme-foreground-palette, text);

$grey-color: mat.get-color-from-palette(mat.$light-theme-foreground-palette, disabled-button);
$grey-medium-color: rgba(0, 0, 0, 0.54);
$grey-light-color: #f5f5f5;
$grey-lightest-color: #fafafa;

$card-color: #f7f9fa;
$card-border-color: #d4d4d5;
$menu-bg-color: $dark-color; //$grey-light-color;
$menu-text-color: $light-color;
$global-menu-shadow: 0 -2px 5px rgb(0 0 0 / 40%);
$page-color: $light-color;

$profile: $primary-color;
$monetize: $secondary-color;
$reward: $tertiary-color;
$sample: $quaternary-color;
$monetize-2: $quinary-color;
$monetize-3: $senary-color;
$monetize-3: $septenary-color;
$productTests: $red-color;
$recruit: $red-color;
$community: $yellow-color;


$color-map: (
  profile: $profile,
  monetize: $monetize,
  reward: $reward,
  sample: $sample,
  productTests: $productTests,
  recruit: $recruit,
  community: $community,
  account: $text-color
);

/***CUSTOM THEME***/
$theme: mat.define-light-theme($primary-palette, $accent-palette, $warn-palette);
$theme-dark: mat.define-dark-theme($primary-palette, $accent-palette, $warn-palette);
