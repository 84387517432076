@import '../../imports/imports';

.mat-menu-panel.find-member-panel {
  max-width: 570px;

  @include media(sm) {
    width: 570px;
  }
}

$menu-width: 170px;

.member-details {
  .back-button {
    left: 20px;
    position: absolute;

    @include media(lg) {
      position: fixed;
      left: 240px;
    }
  }

  @include media(md) {
    &.inner-content .content {
      margin-left: $menu-width;
      width: calc(100% - #{$menu-width});
    }

    .mat-tabs-vertical {
      max-width: $menu-width;
      width: $menu-width;
    }
  }

  .chart-wrapper {
    overflow: hidden;
  }
}

.member-account {
  .mat-card {
    margin-bottom: 1.5rem;
  }

  .parsed-ua-box {
    border: 1px solid $grey-color;
    border-left: 3px solid $community;

    p {
      margin-bottom: 0.3em;
    }

    h3 {
      margin: 0;
    }
  }

  .account-attributes {
    .cropped-cell {
      overflow: hidden;

      .inner {
        @include media-below(sm) {
          display: inline-block;
          max-width: 20vw;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @include media(lg) {
          display: inline-block;
          max-width: 7vw;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @include media(xl) {
          max-width: 8vw;
        }

        @include media(xxl) {
          max-width: none;
        }
      }
    }

    .mat-column-value {
      padding-right: 8px;
    }
  }
}

.members-list {
  .members-list-actions {
    padding-bottom: $main-padding-m;

    .mat-button-toggle-group-appearance-standard {
      @include media-below(sm) {
        width: 100%;

        .mat-button-toggle {
          width: 33.333%;
        }
      }

      .mat-button-toggle-label-content {
        min-width: 100px;
      }
    }
  }
}

.members-list-data {
  padding-top: 0;

  .icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    $icon-size: 18px;

    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      text-align: center;
      color: $dark-color;
      margin: 0 0 0 4px;
      font-size: 1.3rem;

      &.encircled {
        @include circle($icon-size);
        font-size: 0.9rem;
        background-color: $grey-light-color !important;
        color: $light-color !important;
        display: flex;
      }

      &.shape-triangle {
        $triangle-size: 22px;
        width: $triangle-size;
        height: $triangle-size;
        font-size: 0.7rem;
        border-radius: 0;
        background-color: transparent !important;
        color: $light-color !important;
        position: relative;
        z-index: 1;
        transform: translateY(6%);

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: solid transparent;
          border-width: 0 ($icon-size/2) ($icon-size - 1) ($icon-size/2);
          border-bottom-color: $yellow-color;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
          z-index: -1;
        }
      }

      &.shape-triangle, &.encircled {

        &.text-warn {
          background-color: $warn-color !important;
        }

        &.text-alert {
          background-color: $yellow-color !important;
        }

        &.text-success {
          width: $icon-size;
          height: $icon-size;
          background-color: $success-color !important;
        }
      }
    }
  }
}

  .view-invite-link {
    .mat-list {
      .mat-list-item {
        a {
          padding: 10px;
          background-color: $light-color;
          display: flex;
          text-decoration: none !important;
          transition: 0.2s background-color;
          cursor: pointer;

          .mat-icon {
            height: 24px;
            line-height: 24px;
            transition: 0.2s color;
          }

          &:hover {
            background-color: $grey-light-color;
            //color: $light-color;
            .mat-icon {
              //color: $light-color;
            }
          }
        }
      }
    }
  }
