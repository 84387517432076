@import "../imports/imports";

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');


body {
  line-height: 1.5em;
  font-size: 14px;
  color: $text-color;

  &,
  h1, h2, h3, h4, h5, h6,
  .mat-h1, .mat-h2,
  .mat-toolbar, .mat-card, .mat-list-item, .mat-menu-item,
  .mat-tab-label, .mat-tab-link, .mat-dialog-title,
  input, select, textarea, span, p, li, ul, .mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6,
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab, .mat-table,
  .mat-radio-button, .mat-radio-group, .mat-slide-toggle-content {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    font-weight: $fw-light;
    letter-spacing: 0.01em;
    font-size: inherit;
    line-height: inherit;
  }

  p {
    margin-top: 0;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mat-tab-label, .mat-tab-link {
    font-size: 16px;
  }

  .mat-expansion-panel-header, .mat-expansion-panel-header-title {
    font-size: 16px;
    color: $text-color;

    @include media(md) {
      font-size: 18px;
    }
  }

  .mat-h1, .mat-h2,
  .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6,
  h1, h2, h3, h4, h5, h6 {
    font-size: 22px;
    margin: 0 0 8px*2;
  }

  .mat-h1, .mat-toolbar h1, h1 {
    font-size: 28px;
  }

  h2, .mat-toolbar h2, .mat-h2 {
    font-size: 22px;
  }

  h3, .mat-toolbar h3, .mat-h3 {
    font-size: 20px;
  }

  h4, .mat-toolbar h4, .mat-h4 {
    font-size: 18px;
    font-weight: $fw-light;
  }

  h5, .mat-toolbar h5, .mat-h5 {
    font-size: 14px;
  }

  h6, .mat-toolbar h6, .mat-h6 {
    font-size: 12px;
  }

  .text-tiny {
    font-size: 0.7em !important;
  }

  .text-smaller {
    font-size: 0.9em !important;
  }

  .text-small {
    font-size: 1.1em !important;
  }

  .text-large {
    font-size: 2em !important;
  }

  .text-oversized {
    font-size: 3em !important;
  }

  .fw-bold, .bold {
    font-weight: $fw-bold !important;
  }

  .fw-black {
    font-weight: $fw-black !important;
  }

  .fw-light, .no-bold {
    font-weight: $fw-light !important;
  }

  .subtitle {
    font-size: 16px;
    color: $grey-medium-color;
    display: block;
    position: relative;
    padding-top: 8px;
    margin-top: 8px;

    &:before {
      content: "";
      position: absolute;
      background: $primary-color;
      width: 50px;
      left: 50%;
      margin-left: -25px;
      height: 1px;
      top: 0;

      @each $section, $color in $color-map {
        .#{$section} & {
          background-color: $color !important;
        }
      }
    }
  }

  .mat-dialog-container, .mat-card, .mat-tab-label, .mat-tab-link, .mat-toolbar, .mat-drawer-container,
  .mat-cell, .mat-header-cell, .mat-footer-cell, .mat-expansion-panel,
  .mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab,
  .mat-menu-item {
    color: $text-color;

    &.mat-flat-button, &.mat-raised-button, &.mat-fab, &.mat-mini-fab {
      &.mat-primary, &.mat-warn {
        color: $light-color;
      }
    }
  }
}

.mat-icon {
  color: $text-color;

  .mat-mini-fab &, .mat-fab &, .mat-button &, .mat-raised-button &, .mat-icon-button &, .mat-stroked-button &, .mat-flat-button & {
    &:not([class*="text-"]) {
      color: inherit !important;
    }
  }

  .mat-mini-fab &, .mat-fab & {
    vertical-align: inherit !important;
  }
}

.mat-button, .mat-raised-button, .mat-stroked-button, .mat-flat-button {
  &, .mat-button-wrapper {
    line-height: 2.3em;

    .mat-icon {
      left: -5px;
      position: relative;
      font-size: 1.2em;
      width: 18px;
      line-height: 24px;
    }
  }

  &.disabled {
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    cursor: not-allowed;
  }
}

.monospaced {
  font-family: Courier New, Courier, monospace !important;
}

.unselectable {
  color: $grey-medium-color;
  font-style: italic;
}

.link-wrapping {
  white-space: normal !important;
  word-break: break-word !important;
  word-break: break-all !important;
}
