@import "imports/imports";

/*Elements defaults*/
table {
  width: 100%;
}

pre {
  border-radius: 3px;
  border: 1px solid #efefef;
  background: $grey-lightest-color;
  padding: 5px;
}

[hidden] {
  &, &[class*="d-"] {
    display: none !important;
    visibility: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}
/*Utils*/
.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@each $alias, $side in $sides {
  .m#{$alias}-auto {
    margin-#{$side}: auto !important;
  }

  .b#{$alias}-none {
    border-#{$side}: none !important;
  }

  .b#{$alias}-grey {
    border-#{$side}: 1px solid $grey-color !important;
  }

  .b#{$alias}-light-grey {
    border-#{$side}: 1px solid $grey-light-color !important;
  }

  .b#{$alias}-primary {
    border-#{$side}: 1px solid $primary-color !important;

    @each $section, $color in $color-map {
      .#{$section} & {
        border-#{$side}-color: $color !important;
      }
    }
  }

  .b#{$alias}-warn {
    border-#{$side}: 1px solid $warn-color !important;
  }
}

.bt-grey-1 {
  border-top: 1px solid $grey-light-color;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

.height-auto {
  height: auto !important;
}

$numbers: (-1,0,1,2,3,4,5,6,7,8,9);

@each $i in $numbers {
  .m-#{$i} {
    margin: 8px*$i !important;
  }

  .mx-#{$i} {
    margin-left: 8px*$i !important;
    margin-right: 8px*$i !important;
  }

  .my-#{$i} {
    margin-top: 8px*$i !important;
    margin-bottom: 8px*$i !important;
  }

  @each $alias, $side in $sides {
    .m#{$alias}-#{$i} {
      margin-#{$side}: 8px*$i !important;
    }

    .m#{$alias}-auto {
      margin-#{$side}: auto !important;
    }
  }

  .p-#{$i} {
    padding: 8px*$i !important;
  }

  .px-#{$i} {
    padding-left: 8px*$i !important;
    padding-right: 8px*$i !important;
  }

  .py-#{$i} {
    padding-top: 8px*$i !important;
    padding-bottom: 8px*$i !important;
  }

  @each $alias, $side in $sides {
    .p#{$alias}-#{$i} {
      padding-#{$side}: 8px*$i !important;
    }
  }
}

@each $key, $size in $grid-breakpoints {

  .mx-#{$key}-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media($key) {
    @each $i in $numbers {
      .m-#{$key}-#{$i} {
        margin: 8px*$i !important;
      }

      .mx-#{$key}-#{$i} {
        margin-left: 8px*$i !important;
        margin-right: 8px*$i !important;
      }

      .mx-#{$key}-auto {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .my-#{$key}-#{$i} {
        margin-top: 8px*$i !important;
        margin-bottom: 8px*$i !important;
      }

      .p-#{$key}-#{$i} {
        padding: 8px*$i !important;
      }

      .px-#{$key}-#{$i} {
        padding-left: 8px*$i !important;
        padding-right: 8px*$i !important;
      }

      .py-#{$key}-#{$i} {
        padding-top: 8px*$i !important;
        padding-bottom: 8px*$i !important;
      }

      @each $alias, $side in $sides {
        .m#{$alias}-#{$key}-#{$i} {
          margin-#{$side}: 8px*$i !important;
        }

        .m#{$alias}-#{$key}-auto {
          margin-#{$side}: auto !important;
        }

        .p#{$alias}-#{$key}-#{$i} {
          padding-#{$side}: 8px*$i !important;
        }
      }
    }
  }
}

.italic {
  font-style: italic !important;
}

[class*="w-"] {
  box-sizing: border-box;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.medium {
    max-width: 100px;

    @include media(sm) {
      max-width: 200px;
    }

    @include media(md) {
      max-width: 250px;
    }
  }

  &.medium-fixed {
    width: 100px;

    @include media(sm) {
      width: 200px;
    }

    @include media(md) {
      width: 250px;
    }
  }
}

.w-fit-content {
  width: auto !important;
  width: fit-content !important;
}

$widths: ( 100: 100%, 95: 95%, 90: 90%, 85: 85%, 80: 80%, 77: 77%, 75: 75%, 70: 70%, 68: 68%, 67: 67%, 65: 65%, 62: 62%, 60: 60%, 57: 57%, 55: 55%, 50: 50%, 45: 45%, 40: 40%, 35: 35%, 30: 33.3333%, 25: 25%, 20: 20%, 15: 15%, 10: 10%, 5: 5%, 0: 0%, auto: auto, 200px: 200px);

@each $name, $width in $widths {
  .w-#{$name} {
    width: $width !important;
  }

  .max-width-#{$name} {
    max-width: $width !important;
  }
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    .w-#{$key}-fit-content {
      width: auto !important;
      width: fit-content !important;
    }
  }

  @each $name, $width in $widths {
    @include media($key) {
      .w-#{$key}-#{$name} {
        width: $width !important;
      }

      .max-width-#{$key}-#{$name} {
        max-width: $width !important;
      }
    }
  }
}


.mat-form-field {
  width: 100%;
}
/*FlexBox Helpers*/
.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline {
  display: inline !important;
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {

    .overflow-#{$key}-hidden {
      overflow: hidden !important;
    }

    .overflow-#{$key}-visible {
      overflow: visible !important;
    }

    .overflow-#{$key}-auto {
      overflow: auto !important;
    }

    .justify-content-#{$key}-between {
      justify-content: space-between !important;
    }

    .justify-content-#{$key}-around {
      justify-content: space-around !important;
    }

    .justify-content-#{$key}-center {
      justify-content: center !important;
    }

    .justify-content-#{$key}-start {
      justify-content: flex-start !important;
    }

    .justify-content-#{$key}-end {
      justify-content: flex-end !important;
    }

    .align-items-#{$key}-center {
      align-items: center !important;
    }

    .align-items-#{$key}-start {
      align-items: flex-start !important;
    }

    .align-items-#{$key}-stretch {
      align-items: stretch !important;
    }

    .align-items-#{$key}-end {
      align-items: flex-end !important;
    }

    .align-self-#{$key}-center {
      align-self: center !important;
    }

    .align-self-#{$key}-start {
      align-self: flex-start !important;
    }

    .align-self-#{$key}-stretch {
      align-self: stretch !important;
    }

    .align-self-#{$key}-end {
      align-self: flex-end !important;
    }

    .flex-#{$key}-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-#{$key}-wrap {
      flex-wrap: wrap !important;
    }

    .flex-#{$key}-column {
      flex-direction: column !important;
    }

    .flex-#{$key}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-#{$key}-row {
      flex-direction: row !important;
    }

    .flex-#{$key}-row-reverse {
      flex-direction: row-reverse !important;
    }

    .d-#{$key}-none {
      display: none !important;
    }

    .d-#{$key}-block {
      display: block !important;
    }

    .d-#{$key}-flex {
      display: flex !important;
    }

    .d-#{$key}-inline-block {
      display: inline-block !important;
    }

    .d-#{$key}-inline {
      display: inline !important;
    }
  }
}

.btn-row {
  display: flex !important;
  margin: 0 !important;
  padding-top: 8px;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/*Colors*/
.text-primary {
  color: $primary-color !important;
  @include embedded-color-scheme(color);
}

.text-dark {
  color: $text-color !important;
}

.text-darker {
  color: $dark-color !important;
}

.text-accent {
  color: $accent-color !important;
}

.text-warn {
  color: $warn-color !important;
}

.text-light {
  color: $light-color !important;
}

.text-gold {
  color: $gold-color !important;
}

.text-blue {
  color: $blue-color !important;
}

.text-orange {
  color: $orange-color !important;
}

.text-grey {
  color: $grey-color !important;
}

.text-success,
.text-green {
  color: $green-color !important;
}

.text-medium-grey {
  color: $grey-medium-color !important;
}

@each $section, $color in $color-map {
  .text-#{$section} {
    color: $color !important;
  }
}

.bg-none {
  background-color: none !important;
}

.bg-primary {
  background-color: $primary-color !important;
  @include embedded-color-scheme();
}

.bg-accent {
  background-color: $accent-color !important;
}

.bg-warn {
  background-color: $warn-color !important;
}

.bg-light {
  background-color: $light-color !important;
}

.bg-grey {
  background-color: $grey-color !important;
}

.bg-blue {
  background-color: $blue-color !important;
}

.bg-orange {
  background-color: $orange-color !important;
}

.bg-yellow {
  background-color: $yellow-color !important;
}

.bg-grey-lightest {
  background-color: $grey-lightest-color !important;
}

.bg-grey-lightest-hover:hover {
  background-color: $grey-lightest-color !important;
}

.bg-grey-light {
  background-color: $grey-light-color !important;
}

.bg-green {
  background-color: $green-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-sample {
  background-color: $sample !important;
}

.bg-profile {
  background-color: $profile !important;
}

.bg-monetize {
  background-color: $monetize !important;
}

.bg-reward {
  background-color: $reward !important;
}

.bg-productTests {
  background-color: $productTests !important;
}


@each $section, $color in $color-map {
  .bg-#{$section} {
    background-color: $color !important;
  }
}

.box-sizing-content {
  box-sizing: content-box !important;
}

.box-sizing-border {
  box-sizing: border-box !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    text-decoration: none !important;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.text-link-wrapper {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  &:hover {
    text-decoration: none !important;
  }

  &:hover .text-link {
    text-decoration: underline;
  }
}

.selected {
  background-color: $primary-color;
  color: $light-color;
  transition: background-color 0.2s;

  @include embedded-color-scheme();
}

.flex-grow-1 {
  flex-grow: 1;
}

body.mat-typography textarea.pre-textarea {
  white-space: pre-wrap;
  min-height: 250px;
  font-family: monospace !important;
}


.prop-list {
  label:not(.mat-checkbox-layout):not(.mat-radio-label) {
    font-weight: bold;
    display: block;
    margin-bottom: 8px*2;
  }
}


.block-with-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);

  .form-section &, &.form-section, &.action-card, .action-card & {
    &:after {
      background: $card-color;
    }
  }
}

.form-section {
  transition: all 0.15s $easeOut;
  margin-bottom: 20px;
  padding: 7px 10px;
  background: $card-color;
  border-radius: $card-corner-radius;
  box-shadow: 0px 1px 3px 0px $card-border-color, 0px 0px 0px 1px $card-border-color !important;

  &:not(.number) {
    .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
      margin-top: 2em;
    }
  }
}

.pseudo-input {
  padding: 15px 10px 7px;
  background: $light-color;
  border-radius: $card-corner-radius;
  margin-top: 0.25em;
  border: 1px solid $card-border-color;
  position: relative;

  > mat-label {
    top: -1.2em;
    position: absolute;
    left: 5px;
    padding: 5px;
    background: white;
    font-size: 0.8rem;
  }

  .mat-form-field + & {
    margin: 20px 0;
  }
}

.mat-form-field, .pseudo-input {
  &.with-tooltip-top-right {
    .tooltip-trigger {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      z-index: 10;
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-flex {
        padding-right: 35px;
      }

      .tooltip-trigger {
        top: 1px;
        right: -30px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.position-relative, .position-reltive {
  position: relative !important;
}

.nospinner {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: calc($i / 10) !important;
  }
}

@each $key, $size in $grid-breakpoints {
  @include media($key) {
    @for $i from 0 through 10 {
      .opacity-#{$key}-#{$i} {
        opacity: calc($i / 10) !important;
      }
    }
  }
}

.error-message-box, .card-footer-error {
  background-color: $warn-color;
  padding: 10px;
  border-radius: $card-corner-radius;
  color: $light-color;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .mat-icon {
    margin-right: 8px;
    color: $light-color;
  }
}

.mat-card.has-footer-error {
  padding-bottom: 60px !important;

  .card-footer-error {
    position: absolute;
    left: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px 40px;
    min-height: 40px;

    span {
      font-size: 0.8rem;
      line-height: 0.9rem;
    }
  }
}

.spinning-dash {
  height: 10px;
  width: 10px;
  line-height: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
