@import "../imports/imports";

body {
  .mat-form-field.narrow {
    width: auto;

    .mat-form-field-infix {
      width: 80px;
    }
  }

  .mat-form-field.medium {
    width: auto;

    .mat-form-field-infix {
      width: 130px;
    }
  }

  .mat-form-field.wide {
    width: auto;

    .mat-form-field-infix {
      width: 220px;
    }
  }

  .mat-disabled {
    cursor: not-allowed !important;

    .mat-slide-toggle-thumb-container {
      cursor: not-allowed !important;
    }
  }

  .mat-form-field.disabled {
    input, & {
      cursor: not-allowed !important;
    }

    .mat-form-field-outline {
      background: $grey-lightest-color !important;
    }
  }

  .mat-form-field.custom-error {
    .mat-form-field-label {
      color: #e91e63;
    }

    .mat-form-field-ripple, .mat-form-field-ripple.mat-accent, .mat-form-field-ripple {
      background-color: #e91e63;
      opacity: 1;
      transform: scaleX(1);
    }

    &:not(.mat-focused) {
      .mat-form-field-ripple, .mat-form-field-ripple.mat-accent, .mat-form-field-ripple {
        height: 1px;
      }
    }
  }

  .mat-select {
    .mat-select-value {
      .additional-selection {
        opacity: 0.75;
        font-size: 0.75em !important;

        &.clear {
          position: absolute;
          margin-left: 5px;
          margin-top: 3px;
        }
      }
    }
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    @include embedded-color-scheme();
    color: $light-color !important;
  }

  .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    color: $light-color !important;
    @include embedded-color-scheme(background-color);

    &:after {
      top: 3px;
      left: 2px;
    }
  }

  .mat-form-field {
    line-height: 120%;

    .mat-form-field-infix {
      @include media-below(md) {
        width: auto;
      }
    }

    &.no-labels {
      .mat-form-field-wrapper {
        padding: 0 !important;
      }
    }

    &.mat-form-field-appearance-legacy {
      .mat-form-field.pt-0 {
        .mat-form-field-infix {
          padding-top: 0;
          margin-top: 0;
          border-top: none;
        }
      }
    }

    &.mat-form-field-appearance-outline {
      mat-label {
        position: relative;

        &:after {
          content: "";
          width: 0;
          height: 55%;
          z-index: -1;
          bottom: 0;
          left: 0;
          position: absolute;
          transition: width 0.3s $easeOutExpo 0.1s;
        }
      }

      textarea.mat-input-element {
        padding: 7px 0;
        margin: 0;
        min-height: 70px;
      }

      .mat-select-arrow-wrapper {
        transform: none;

        .mat-select-arrow {
          margin-top: 4px;
        }
      }

      .mat-form-field-prefix {
        top: -0.05em;
        font-size: 20px;
      }

      .mat-form-field-label {
        min-height: 18px;
      }

      .mat-form-field-label-wrapper {
        top: -0.6em;
        padding-top: 0.6em;
      }

      .mat-form-field-wrapper {
        padding: 1em 0;
      }

      &.mat-form-field-can-float {
        .mat-input-server:focus,
        &.mat-form-field-should-float {

          .mat-form-field-label-wrapper {
            top: -.8em;
            padding-top: .8em;
          }

          .mat-form-field-label {
            transform: translateY(-1em) scale(0.75);

            mat-label {
              position: relative;
              color: $text-color;

              &:after {
                background: $light-color;
                width: 105%;
              }
            }
          }
        }

        &.pt-0 {
          .mat-form-field-wrapper {
            padding: 0 0 1em;
          }
        }

        &.pb-0 {
          .mat-form-field-wrapper {
            padding: 1em 0 0;
          }
        }
      }

      .mat-form-field-infix {
        padding: 0.6em 0;
        border-top: 0;
      }

      .mat-form-field-outline {
        background: $light-color;
      }

      &:not(:focus):not(:hover):not(:active) {
        .mat-form-field-outline {
          color: $card-border-color;
        }
      }

      &:hover, &:active, &:focus {
        .mat-form-field-outline {
          color: $text-color;
        }
      }
    }
  }

  .mat-form-field-label {
    font-size: 1rem;
  }

  .mat-input-element,
  .mat-select-value {
    padding: 2px 0;
    margin-top: 0.2em;
    margin-bottom: -2px;
    min-height: 23px;
    box-sizing: content-box;

    &.mat-select-value {
      padding: 6px 0 0;
    }

    &, * {
      font-size: 1rem !important;
    }
  }

  .mat-form-field.input-small {
    .mat-input-element, * {
      font-size: 0.8rem !important;
    }

    .mat-form-field-infix {
      padding: 0.5em 0 0.4em 0;
    }
  }

  .mat-form-field.w-auto {
    min-width: 180px;
  }

  .mat-form-field.percentage-input {
    .mat-form-field-infix {
      .mat-input-element {
        text-align: right;
      }
    }

    .mat-form-field-suffix {
      top: -2px;
    }
  }

  .mat-radio-group {
    .mat-radio-button {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .mat-radio-label-content, .mat-radio-label-content span {
    font-size: 15px;
  }

  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 2px;

    .mat-slide-toggle-thumb {
      height: 19px;
      width: 19px;
    }
  }

  .mat-slide-toggle-bar {
    width: 39px;
    height: 24px;
    border-radius: 12px;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $grey-lightest-color;
  }

  .mat-slide-toggle.binary:not(.mat-disabled) .mat-slide-toggle-bar {
    background: $red-color;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $success-color;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $success-color;
  }

  .small-checks {
    .mat-checkbox, .mat-pseudo-checkbox, .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }
  }

  .mat-radio-button .text-input {
    border: none;
    border-bottom: 1px solid;
    width: 80px;
  }
}

input[type="file" i]::-webkit-file-upload-button {
  background: $text-color;
  border-radius: 2px;
  border: none;
  box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
  color: $light-color;
  padding: 0 16px;
  line-height: 36px;
  font-family: $font-family-body;
  cursor: pointer;
}
