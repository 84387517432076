@import '../../imports/imports';

.recruit {
  .main-content {
    @include media(lg) {
      min-height: 50vh;
    }
  }

  .recruit-list-view {
    .mat-card.actionable-card:not(.placeholder-box) {
      padding-bottom: 100px;
    }

    .mat-grid-tile {
      overflow: visible;

      .mat-grid-tile-content {
        align-items: end;
      }
    }

    recruit-campaign-sparkline-chart, recruit-source-sparkline-chart {
      min-width: 50%;
      display: block;
    }

    .chart-wrapper {
      min-height: 70px;
      max-width: 100%;
      width: 100%;

      &.PieChart {
        min-height: 90px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .source-details {
    @include media(md) {

      .mat-tabs-vertical {
        max-width: 170px;
        width: 170px;
      }

      &.inner-content .content {
        margin-left: 170px;
        width: calc(100% - 170px);
      }
    }

    .campaigns-card {
      padding: 0;

      .mat-nav-list {
        padding: 0;

        .mat-list-item {
          border-bottom: $default-border rgba(0, 0, 0, 0.05);

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }

  .recruit-details {
    @include media-below(md) {
      padding-left: 8px;
      padding-right: 8px;

      .inner-content {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    @include media-below(sm) {
      padding-left: 0;
      padding-right: 0;

      .inner-content {
        padding-left: 24px;
        padding-right: 24px;

        .table-outer-wrapper {
          margin-left: -24px;
          margin-right: -24px;
        }

        .mat-paginator-container {
        }

        .mat-paginator-page-size-select {
          margin-top: 0;
        }
      }
    }

    .stat-box-wrapper {
      margin: 0 -10px 20px;
    }

    .stat-box {
      border-radius: $card-corner-radius;
      background-color: $recruit;
      color: $light-color;
      margin: 0 8px;
      width: 33%;
      text-align: center;
      padding: 10px;

      b, span {
        display: block;
      }
    }

    .floating-button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 52px;

      @include media(md) {
        position: absolute;
        top: 40px;
        right: 40px;
        height: 40px;
      }
    }
  }

  .pending-activities {
    .mat-list-item {
      .mat-list-item-content {
        align-items: flex-start;
      }
    }
  }

  .activity-table {

    .pending {
      opacity: 0.8;
      pointer-events: none;

      .mat-cell {
        background-color: $grey-lightest-color;
        font-style: italic;
      }
    }
  }

  .campaign-config {
    dl {
      display: flex;
      flex-wrap: wrap;
      border: 1px $card-border-color solid;
      border-radius: $card-corner-radius;

      dd, dt {
        border-bottom: 1px $card-border-color solid;
        margin: 0;
        padding: $mat-card-padding;
        font-size: 0.8rem;

        &:last-of-type {
          border-bottom: none;
        }
      }

      dt {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
        color: $recruit;
        font-weight: $fw-bold;
        background: transparentize($recruit, 0.95);
        border-right: 1px transparentize($grey-medium-color, 0.52) solid;
      }

      dd {
        flex-grow: 1;
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }

      @include media(sm) {
        dt {
          min-width: 40%;
          max-width: 40%;
          width: 40%;
        }

        dd {
          min-width: 60%;
          max-width: 60%;
          width: 60%;
        }
      }

      @include media(md) {
        dt {
          min-width: 30%;
          max-width: 30%;
          width: 30%;
        }

        dd {
          min-width: 70%;
          max-width: 70%;
          width: 70%;
        }
      }

      @include media(lg) {
        &.split-30-70 {
          dt {
            min-width: 30%;
            max-width: 30%;
            width: 30%;
          }

          dd {
            min-width: 70%;
            max-width: 70%;
            width: 70%;
          }
        }

        &.split-50-50 {
          dt {
            min-width: 50%;
            max-width: 50%;
            width: 50%;
          }

          dd {
            min-width: 50%;
            max-width: 50%;
            width: 50%;
          }
        }
      }
    }

    .link-wrapper {
      position: relative;
      cursor: pointer;

      .mat-icon-button {
        position: absolute;
        right: -10px;
        top: 2px;
        opacity: 0;
        transition: all 0.1s;
        border-radius: 0;
      }

      &:hover {

        .mat-icon-button {
          right: 2px;
          opacity: 1;
          background-color: $card-color;
        }
      }
    }

    pre {
      background-color: $card-color;
      padding: 7px 20px;
    }
  }
}

.view-activity-report-dialog {
  .cards-wrapper {
  }

  .card-outer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;

    @include media(sm) {
      padding-bottom: 20px;
      flex-direction: row;
    }
  }

  .mat-card {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @include media(sm) {
      width: 48%;
      margin-bottom: 0;
    }

    @include media(md) {
      width: 49%;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.activity-list {
  .table-outer-wrapper .table-footer {
    border-bottom: none;
  }
}

.wide-table {

  .members-list-data {
      table {
          min-width: 1500px;
      }
  }
}
