@import "../imports/imports";

.sidenav-container {
  min-height: 100%;
  min-height: 100vh;

  @include media(md) {
    height: 100%;
  }

  .mat-drawer-side {
    border: none;
    box-shadow: $global-menu-shadow;
  }

  .logo {
    text-align: center;
    justify-content: center;
    height: auto;
    background-color: $menu-bg-color;
    padding: 10px 0 0;

    @include media(md) {
      padding: 18px 0 10px;
    }

    a {
      color: $primary-color;
      font-weight: 900;
      text-decoration: none;
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 2.5em;

      .mat-icon {
        font-size: inherit;
        width: auto;
        height: auto;
        transform: rotate(180deg) scale(0.9);
        transition: transform 0.5s $easeOutBack;

        &.loaded {
          transform: rotate(0deg) scale(1);
        }
      }
    }
  }
}

.mat-drawer-container {
  background-color: $page-color;

  .mat-drawer-content.mat-sidenav-content {
    @include media(sm) {
      margin-left: $menu-width-m !important;
    }

    @include media(md) {
      margin-left: $menu-width !important;
    }

    &.m-0 {
      margin-left: 0 !important;
    }
  }
}

.sidenav {
  width: $menu-width-m;

  &, &.mat-drawer.mat-drawer-push {
    background: $menu-bg-color;
  }

  @include media(md) {
    width: $menu-width;
  }

  .mat-nav-list {
    .mat-list-item {
      height: 80px;
      position: relative;
      transition: background 0.3s;

      .mat-list-item-content {
        color: transparentize($menu-text-color, 0.3);
        flex-direction: column;
        justify-content: center;
        opacity: 0.8;

        @include media(md) {
          transition: opacity 0.2s, padding 0.1s $easeOut;
          opacity: 0.4;
        }

        .mat-icon {
          font-size: 2em;
          color: inherit;
          text-align: center;
          width: auto;
          height: auto;

          @include media(md) {
            transition: opacity 0.4s;
            margin-top: 0;
          }
        }

        span {
          padding-top: 4px;
          font-size: 0.8em;
          line-height: 1em;
          text-align: center;

          @include media(md) {
            opacity: 0;
            max-height: 0;
            transition: opacity 0.1s, max-height 0.2s;
          }
        }
      }

      @include media(md) {
        &:hover, &.active {
          background: transparent;

          .mat-icon {
            transition: opacity 0.3s;
          }

          span {
            max-height: 30px;
            opacity: 1;
            transition: opacity 0.2s, max-height 0.2s;
          }

          .mat-list-item-content {
            opacity: 1;
            color: transparentize($menu-text-color, 0.2);
            transition: opacity 0.2s, padding 0.1s $easeOut;
          }

          &:before {
            width: 100%;
          }
        }
      }

      &.active {
        opacity: 1;
        background: transparent;

        span {
          opacity: 1;
        }

        .mat-list-item-content {
          opacity: 1;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        transition: width 0.2s $easeOutExpo;
        background: $light-color;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
      }

      @each $area, $color in $color-map {
        &.#{$area} {
          &:hover, &:before, &.active {
            background: $color;
          }
        }
      }
    }
  }

  &:hover {
    .mat-nav-list {
      .mat-list-item {
        .mat-list-item-content {
        }
      }
    }
  }
}
