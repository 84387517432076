@import "../../imports/imports";

.avatar-preview {
  background: no-repeat center center $grey-color;
  background-size: contain;
  position: relative;
  z-index: 1;
  border: none;
  width: 150px;
  height: 150px;
  left: 1px;

  &.hasImage, &.sm {
    background-color: transparent;
  }

  .avatar-preview-inner {
    transition: background-color 0.1s $easeInOut;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: no-repeat center center transparent;
    background-size: contain;
  }

  @include media(sm) {
    width: 200px;
    height: 200px;
  }

  &.sm {
    width: 50px;
    height: 50px;
    left: 0;

    .avatar-preview-inner {
      line-height: 50px;
    }
  }
}
