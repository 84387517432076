@use '@angular/material' as mat;
@import "../imports/imports";

body {

  .mat-chip {
    white-space: nowrap;
    text-decoration: none;
    min-height: unset;
    height: auto;

    &.more {
      background-color: $light-color;
      border: 1px solid $grey-color;
    }

    &.mat-success {
      background-color: $success-color;
      color: $light-color;
    }

    &.mat-warn {
      background-color: $warn-color;
      color: $light-color;
    }

    &.bg-primary {
      color: $light-color;
      @include embedded-color-scheme();
    }

    &.mat-secondary {
      background-color: $secondary-color;
      color: $light-color;
    }

    &.mat-tertiary {
      background-color: $tertiary-color;
      color: $light-color;
    }

    &.mat-quaternary {
      background-color: $quaternary-color;
      color: $light-color;
    }
  }

  .mat-chip-list {
    &.contain-chips {
      overflow: hidden;
      text-overflow: ellipsis;

      .mat-chip {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }

  .mat-chip-list-wrapper {
    margin: -2px;

    .mat-chip {
      white-space: nowrap;
      font-size: 12px;
      font-weight: $fw-light;
      padding: 3px 8px;
      letter-spacing: 0.02em;
      margin: 3px 2px;
    }
  }

  .long-list, .short-list {
    position: relative;
    min-width: 100px;
    width: 100%;
    height: 30px;

    .mat-chip-list-wrapper {
      position: absolute;
      top: 5px;
      left: 0;
      max-height: 100%;
      width: 100%;
      padding: 0;
      overflow: hidden;

      .mat-chip {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }

    &.short-list {
      overflow: hidden;
    }

    &.long-list {
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.2s $easeOut;

      .mat-chip-list-wrapper {
        z-index: -1;
        opacity: 0;
        width: 300px;
        max-width: none;
        padding: 10px;
        top: -5px;
        left: -10px;
        background: $light-color;
        max-height: 500px;
        transition: opacity 0.2s $easeOut 0.2s;
        @include mat.elevation(1, transparentize(#000, 0.4));
      }
    }
  }

  .nowrap {
    &:hover {
      .short-list {
        z-index: 100;
        overflow: visible;
      }

      .long-list {
        opacity: 1;
        transition: opacity 0.2s $easeOut 0.4s;

        .mat-chip-list-wrapper {
          z-index: 100;
          opacity: 1;
          background: $light-color;
          transition: opacity 0.2s $easeOut 0.8s;
        }
      }
    }
  }
}
