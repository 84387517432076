@import "../../imports/imports";

.chart-wrapper {
  position: relative;

  .chart-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize($light-color, 0.05);
    z-index: 2;
    display: flex;
    margin: 0 !important;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .mat-card.actionable-card:not(:hover) & {
    .chart-loader {
      background: transparentize($card-color, 0.05);
    }
  }
}
