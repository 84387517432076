@import "../../imports/imports";

.home {
}

.home-nav {
  .mat-nav-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .mat-list-item {
      height: auto;
      font-size: 1.3em;
      overflow: hidden;
      transition: background-color 0.2s;
      text-align: center;

      @include media(sm) {
        max-width: 50%;
        width: 144px;
      }

      @include media(md) {
        max-width: 33%;
      }

      @include media(lg) {
        max-width: 25%;
      }

      /*


      */
      .mat-list-item-content {
        flex-direction: column;
        padding: 8px*3;
        min-width: 150px;

        .mat-icon {
          text-align: center;
          font-size: 2.8em;
          color: $dark-color;
          width: 100%;
          height: auto;
          opacity: 0.3;
          transform: scale(1);
          transition: transform 0.2s, opacity 0.2s;
        }

        span {
          padding-top: 10px;
        }
      }

      &:hover {
        .mat-icon {
          transform: scale(1.13);
          opacity: 1;
          transition: transform 0.6s $easeOutExpo, opacity 0.2s;
        }

        @each $area, $color in $color-map {
          &.#{$area} {
            .mat-icon {
              color: $color;
            }
          }
        }
      }
    }
  }
}
