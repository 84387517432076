@import '../../imports/imports';

.reward {
  .availableRewards img {
    height: auto;
    max-width: 40px;

    @include media(md) {
      max-width: 50px;
    }
  }

  .auto-deliver {
    .mat-icon {
      font-size: 1.5em;
      height: 20px;
      width: 20px;
    }
  }
}

.find-reward-menu {
  .mat-menu-content {
    position: relative;
  }

  &.error-state {
    .mat-menu-content {
      padding-bottom: 60px;
    }
  }

  .error-message-box {
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    padding: 10px 18px;
    font-size: 0.8rem;
  }
}
