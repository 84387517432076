@import "../imports/imports";

body {
  .cdk-global-overlay-wrapper {
    justify-content: center;
    align-items: flex-start;
  }

  .mat-snack-bar-container {
    &.mat-snack-bar-top {
      @include media(md) {
        margin-top: 0;
        border-radius: 0 0 5px 5px;
      }
    }

    .mat-simple-snackbar {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:before {
        @include material-icon();
        font-size: 30px;
        margin-right: 10px;
        color: $light-color;
      }
    }

    &.snackbar-error {
      background-color: $warn-color;

      .mat-simple-snackbar:before {
        content: "error";
      }
    }

    &.snackbar-success {
      background-color: $success-color;

      .mat-simple-snackbar:before {
        content: "check_circle";
      }
    }

    &.snackbar-info {
      background-color: $dark-color;

      .mat-simple-snackbar:before {
        content: "info";
      }
    }
  }
}
