@import '../../imports/imports';

.main-product-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 66%;
  height: 0;
  border: 1px solid $grey-light-color;

  &.xs {
    width: 40px;
    padding-bottom: 0;
    height: 40px;
  }

  &.sm {
    width: 80px;
    padding-bottom: 0;
    height: 80px;
  }

  &.md {
    width: 120px;
    padding-bottom: 0;
    height: 80px;
  }
}

.product-tests {
  .mat-card.actionable-card {
    .mat-card-footer {
        padding-top: 0;
    }

    @include media(md) {
      padding-bottom: 40px;
    }

    .main-product-image {
      background-size: contain;
      padding-bottom: 0;
      height: 100px;
      border: none;
    }
  }

  .mat-card-title {
    padding: 0 !important;
    border: none !important;
  }

  .actionable-card-title {
    h4 {
      border-bottom: 1px solid $dark-color;
      padding: 0.5rem 0.7rem;

      &.open {
        border-bottom-color: $green-color;

        &.paused {
          border-bottom-color: $yellow-color;
        }
      }

      &:not(.open) {
        border-bottom-color: $red-color;
      }
    }
  }

  .mat-card {
    .main-product-image {
    }
  }

  .mat-table {
    .main-product-image {
      width: 40px;
      padding-bottom: 0;
      height: 40px;
    }
  }
}

.product-details {
  .main-product-image {
    width: 200px;
    height: 200px;
    padding: 0;

    &.sm {
      width: 80px;
      padding-bottom: 0;
      height: 80px;
    }
  }
}

.add-edit-campaign-dialog {
  .mat-horizontal-content-container {
    padding-top: 20px;
  }
}
