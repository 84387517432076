@import "../../imports/imports";

.code-snippet {
  position: relative;

  button {
    position: absolute;
    top: 5px;
    right: 2px;
    z-index: 10;
    opacity: 0.6;
    border-radius: 0 3px 0 15px;
    min-width: 0;

    .inner-text {
      text-align: right;
      overflow: hidden;
      display: inline-block;
      max-width: 0;
      opacity: 0;
      transition: all 0.2s;
    }
  }

  &:hover {
    button {
      opacity: 1;

      .inner-text {
        max-width: 100px;
        opacity: 1;
      }
    }
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $primary-color;
    border-radius: 3px;
    font-size: 0.8rem;
  }
}
