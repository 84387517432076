@import "../../imports/imports";

body {
  .introjs-tooltip {
    position: fixed;
    margin-left: -150px;
    width: 300px;
    bottom: 20px;
    left: 50% !important;
    top: auto !important;

    .introjs-arrow {
      display: none !important;
    }
  }

  .introjs-helperNumberLayer {
    display: none;
  }

  .introjs-helperLayer[style*="width: 0px"] {
    opacity: 0;
  }

  .introjs-tooltipbuttons {
    padding-top: 8px;
    display: flex;
  }

  .introjs-button {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    border: none;
    border-radius: 0;
    background: $primary-color;
    color: $light-color;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 58px;
    line-height: 26px;
    padding: 0 16px;
    border-radius: 2px;
    overflow: visible;
    margin-left: 8px;
    text-shadow: none;
    font-size: 14px;

    &:active, &:focus {
      background: $primary-color;
      color: $light-color;
    }

    &:disabled, &.introjs-disabled {
      background: $grey-light-color;
      color: $dark-color;
    }

    &.introjs-skipbutton {
      margin: 0 auto 0 0;
    }

    &.introjs-prevbutton, &.introjs-nextbutton {
      font-family: 'Material Icons' !important;
    }
  }
}
