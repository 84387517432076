@import "../imports/imports";

html, body {
  min-height: 100%;
  min-height: 100vh;

  @include media(md) {
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: $font-family-body;

  &, * {
    box-sizing: border-box;
  }

  :after, :before {
    box-sizing: border-box;
  }
}

.main-content {
  display: block;
  background: $light-color;
  position: relative;
  min-height: 200px;
  padding-bottom: 150px; //extra space to allow for floating action-nav icons

  @include media(md) {
    padding-bottom: 0;
  }

  .inner-content {
    display: block;
    width: 100%;
    max-width: map-get($grid-breakpoints, lg);
    margin: 0 auto;
    overflow: visible;
    padding: ($main-padding-m*2) $main-padding-m;

    @include media(sm) {
      padding: ($main-padding/2) $main-padding;
    }

    @include media(md) {
      padding: $main-padding;
    }

    .table-outer-wrapper {
      margin-left: -$main-padding-m;
      margin-right: -$main-padding-m;
    }

    @include media(md) {
      .table-outer-wrapper {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.xs {
      max-width: 400px;
    }

    &.sm {
      max-width: map-get($grid-breakpoints, sm);
    }

    &.md {
      max-width: map-get($grid-breakpoints, md);
    }

    &.lg {
      max-width: 100%;
    }

    .content {
      @include media(md) {
        width: calc(100% - 101px);
        box-sizing: border-box;
        margin-left: 101px;
      }

      @include media(lg) {
        //width: calc(100% - 151px);
        //margin-left: 151px;
      }
    }

    &.mat-divider {
      padding: 0;
    }
  }
}

.overflow-shadow-wrapper {
  position: relative;

  &.overflow-left, &.overflow-right {
    overflow: hidden;

    &:after, &:before {
      box-shadow: 0 0 15px 1px $black-87-opacity;
      //height: calc(100% - 50px);
      width: 1px;
      z-index: 110;
      position: absolute;
      top: 20px;
      bottom: 0;
      right: -1px;
      left: -1px;
    }
  }

  &.overflow-left {
    &:after {
      right: auto;
      content: "";
    }
  }

  &.overflow-right {
    &:before {
      left: auto;
      content: "";
    }
  }

  .overflow-scroller {
    position: relative;
    height: 200px;
    padding-top: 20px;
  }

  .overflow-inner {
    position: absolute;
  }
}


.details {

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $light-color;
    opacity: 0.7;
    top: 0;
    left: 0;

    &.invisible {
      position: fixed;
      background: transparent;
      z-index: 1000;
    }

    input {
      opacity: 0;
    }
  }
}
