@import '../../imports/imports';

.dashboard-list {
  .mat-selection-list {
    .hide-check {
      .mat-pseudo-checkbox {
        display: none;
      }
    }

    .mat-list-text {
      padding-left: 0 !important;
    }

    .mat-list-option {
      padding: 0;

      .mat-list-item-content {
        padding: 10px;
        align-items: flex-start !important;

        .mat-pseudo-checkbox {
          margin-top: 5px;
        }
      }

      .text-link {
        text-decoration: none;
        display: inline-block;
        vertical-align: top;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.dashboard-panel {
  $metric-box-text-color: rgba(255, 255, 255, 0.9);
  $metric-box-bg-color: $green-color;
  min-height: 200px;
  overflow: hidden;
  transition: filter 0.3s $ease-out, opacity 0.3s $ease-out;

  @include media(lg) {
    width: 100%;
    box-sizing: border-box;
  }

  .overflow-shadow-wrapper, .table-wrapper,
  .overflow-shadow-wrapper .overflow-inner {
    width: 100%;
  }

  .overflow-shadow-wrapper .overflow-scroller {
    padding: 0;
  }

  .table-wrapper {
    min-height: 0;

    tr.mat-header-row {
      height: 30px;
    }
  }

  h5 {
    font-size: 1em;
    font-weight: $fw-light;
    white-space: nowrap;
    color: $grey-medium-color;
  }


  .mat-divider {
    z-index: 20;
  }

  .user-experience {
    overflow: auto;
    padding-bottom: 8px*2;

    .mat-divider-horizontal {
      position: relative;
      left: auto;
    }
  }

  .stat-pair {
    text-align: center;
    margin: 10px 0;
    min-width: 50%;

    h1, h2 {
      font-size: 2em;
      font-weight: $fw-light;
      position: relative;
      margin: 0;

      &:after {
        //content:""; //hidden
        position: absolute;
        display: block;
        width: 60px;
        left: 50%;
        height: 1px;
        margin-left: -30px;
        bottom: 0;
        background: $accent-color;
      }
    }

    h2 {
      font-size: 1.5em;
    }

    h5 {
      margin: 0;
    }

    &.large {
      h1 {
        font-size: 3em;
      }
    }
  }

  .stat-pair-box {
    background: $metric-box-bg-color;
    color: $metric-box-text-color;
    padding: (8px*2) 8px;
    margin-bottom: 20px;
    font-size: 0.8rem;
    line-height: 1.2rem;

    @include media(md) {
      width: 50%;
      max-width: 130px;
    }

    .stat-pair {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }

      h1 {
        line-height: 1.6rem;
      }
    }

    h1, h2, h3, h5 {
      color: inherit;
    }
  }
}

%chart-label {
  display: block;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 100px;
  margin-left: -45px;
  margin-top: -15px;

  span {
    font-size: 18px;
  }
}

.chart-wrapper {
  position: relative;
  margin-top: 40px;
  min-height: 120px;

  .label {
    display: none;
  }

  .over-banner {
    @extend %chart-label;
    background: $grey-color;
    padding: 10px;
    color: $light-color;
    top: 30%;
  }

  &.PieChart {
    .label {
      @extend %chart-label;
    }
  }
}

.dashboard-dialog {
  tr.mat-header-row {
    height: 25px;
    vertical-align: top;
  }
}

google-chart div {
  margin: 0 auto;
}

.blur {
  filter: blur(4px);
  opacity: 0.8;
  transition: none;
  display: block;
}

.blur-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;

  h1 {
    padding: 30px;
    background: $grey-color;
    color: $light-color;
  }
}
