@import "../imports/imports";

ul:not(.bullet-list) { padding: 0; margin: 0;
  li { list-style: none; }
}
* { image-rendering: -webkit-optimize-contrast; }
object {
  &, * { display: block; outline: none !important; border: none !important; box-shadow: none !important; }
}

a:not([class]) {
  transition:all 0.2s $easeOut;

  &:hover { cursor: pointer; text-decoration: underline; }
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-all {
  pointer-events: all !important;
}

.word-break-all {
  word-break: break-word;
  word-break: break-all;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
