@import "../imports/imports";

body {
  .mat-icon.disabled {
    opacity: 0.3;
  }

  .mat-stroked-button {
    transition: border-color 0.1s;

    &.disabled, &:disabled {
      border: $light-color;
      color: $dark-disabled-text;
    }

    &:not(.disabled):not(:disabled) {

      &.mat-primary {
        @include embedded-color-scheme(color);
        @include embedded-color-scheme(border-color, 0.4);

        &.active, &:hover {
          @include embedded-color-scheme(border-color, 0.1);

          .mat-button-focus-overlay {
            opacity: 0.1;
          }
        }

        .mat-ripple-element {
          @include embedded-color-scheme();
        }

        .mat-button-focus-overlay {
          @include embedded-color-scheme();
        }
      }

      &.mat-success {
        color: $success-color;
        border-color: transparentize($success-color, 0.4);

        &.active, &:hover {
          border-color: transparentize($success-color, 0.1);

          .mat-button-focus-overlay {
            opacity: 0.1;
          }
        }

        .mat-ripple-element {
          background-color: $success-color;
        }

        .mat-button-focus-overlay {
          background-color: $success-color;
        }
      }

      &.mat-warn {
        color: $warn-color;
        border-color: transparentize($warn-color, 0.4);

        &.active, &:hover {
          border-color: transparentize($warn-color, 0.1);

          .mat-button-focus-overlay {
            opacity: 0.06;
          }
        }

        .mat-ripple-element {
          background-color: $warn-color;
        }

        .mat-button-focus-overlay {
          background-color: $warn-color;
        }
      }
    }
  }


  .mat-flat-button.mat-light {
    &.pl-0, &.pr-0 {
      &:hover, &:active, &:focus {
        background: $black-12-opacity;
        padding: 0 (8px*2) !important;
        transition: padding 0.1s ease-out;
      }
    }
  }

  .mat-flat-button.mat-success,
  .mat-flat-button.bg-success,
  .mat-flat-button.bg-green {
    &, &:hover, &:active, &:focus {
      background-color: $success-color;
      color: $light-color;
    }
  }

  .mat-icon-button {
    &.sm, &.small {
      width: 30px;
      height: 30px;
    }
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
  .mat-button-toggle-checked {
    color: $light-color;
    @include embedded-color-scheme();
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-mini-fab {
    &.mat-primary:not(.disabled):not(:disabled) {
      color: $light-color;
      @include embedded-color-scheme();
    }
  }

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button {
    &.mat-primary:not(.disabled):not(:disabled) {
      @include embedded-color-scheme(color);
    }
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    &.sm {
      font-size: 0.8rem;
      padding: 0 10px;
      min-width: 30px;
      line-height: 30px;
    }
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    &.square {
      padding: 0 10px;
      min-width: 40px;
      line-height: 40px;
    }
  }

  .mat-button-toggle.sm {
    .mat-button-toggle-label-content {
      line-height: 38px;
    }
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
  }
}
