@import "../../imports/imports";

$ribbon-size: 85px;

.ribbon-wrapper {
  @include size($ribbon-size);
  overflow: hidden;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 100;
  pointer-events: none;

  .ribbon {
    background-color: $warn-color;
    color: #fff;
    font-size: 0.7rem;
    left: -5px;
    line-height: 1.4em;
    padding: 2px 7px;
    position: relative;
    text-align: center;
    top: 15px;
    transform: rotate(45deg);
    width: 120px;

    .info &    { background-color: $yellow-color; }
    .warn &    { background-color: $warn-color; }
    .accent &  { background-color: $accent-color; }
    .primary & { background-color: $success-color; }
    .success & { background-color: $success-color; }
    .thin &  { padding-bottom: 3px; padding-top: 3px; }
    .thick & { padding-bottom: 7px; padding-top: 3px; }
  }
}
