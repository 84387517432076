@import "../../imports/imports";

.nPanel_DataTable {
  .mat-cell {
    &.wide {
      width: 20%;
    }
  }
}

.table-outer-wrapper {
  
  .table-footer {
    position: relative;
    z-index: 120;
    background: $light-color;
    border-bottom: 1px solid $black-12-opacity;

    .no-bottom-border & {
        border-bottom: 0;
    }

    &.hide-pagesize {
      .mat-paginator-outer-container {
        justify-content: space-between;

        .mat-paginator-navigation-first {
          order: -2;
        }

        .mat-paginator-navigation-previous {
          order: -1;
        }

        .mat-paginator-range-actions {
          justify-content: center;
          width: 100%;
        }
        /*
        .mat-paginator-range-label {
          margin-right: auto;
          margin-left: 0;
        }*/
      }
    }
  }

  &.clickable {
    tr:hover {
      td {
        background-color: $grey-light-color;

        &:not(.action-cell) {
          cursor: pointer;
        }
      }
    }
  }
}

.table-wrapper {
  overflow-y: auto;
  max-width: 100%;
  min-height: 500px;

  &.hide-header {
    tbody tr:first-child td {
      border-top: 1px solid $black-12-opacity;
    }
  }

  .short-table & {
    min-height: 0;
  }

  .mid-table & {
    min-height: 180px;
  }

  .mat-cell, .mat-footer-cell, .mat-header-cell {
    padding-left: 8px;

    &:last-child {
      padding-left: 8px;
    }

    .mat-expansion-panel & {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &.narrow {
      padding-left: 0;
    }

    .mat-sort-header-button {
      text-align: left;
    }

    .cell-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(0,0,0,0.05);

      &.temporary {
        animation: fade-out forwards 0.3s $easeInExpo;
      }
    }
  }

  .mat-header-cell {
    &.action-cell {
      padding-right: 8px;
      text-align: right;

      &:last-child {
        padding-right: 28px;
      }
    }
  }

  tr.mat-footer-row, tr.mat-row {
    height: auto;

    &.text-warn {
      td {
        color: $warn-color;
      }
    }

    &.text-success {
      td {
        color: $success-color;
      }
    }

    .has-total-row & {
      &:last-of-type {
        .mat-cell {
          border-top: 1px solid $black-12-opacity;
          border-bottom: 2px solid $black-12-opacity;
        }
      }

      &:nth-last-of-type(2) {
        .mat-cell {
          border-bottom: 1px solid $black-12-opacity;
        }
      }
    }
  }

  .mat-cell {
    position: relative;
    font-size: 0.8rem;
    padding-top: 6px;
    padding-bottom: 6px;

    .mat-icon-button {
      width: 30px;
      height: 30px;
      margin: 0;
      font-size: 1.5em;

      .mat-icon {
        font-size: inherit;
      }
    }

    .empty-cell {
      background: $grey-lightest-color;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &.chips {
      &.nowrap {
        overflow: visible;
      }
    }
  }
}

.mat-paginator-container {
  justify-content: space-between !important;
}

.table-chart {
  .bar {
    height: 5px;
    //border: 1px solid $primary-color;
    background: $grey-light-color;
    display: block;
    width: 90%;
    bottom: 5px;
    position: absolute;
    left: 0;

    .fill {
      background: $primary-color;
      height: 100%;
      display: block;
    }
  }
}


.table-link {
  text-decoration: none;

  &, &:visited, &:not([href]) {
    color: inherit;
  }

  tr:hover & {
    text-decoration: underline;
  }
}


.compact-table {
  .table-wrapper {
    min-height: 0;
  }

  .mat-row, .mat-footer-row {
    height: 32px;
  }

  .mat-cell, .mat-footer-cell {
    padding-top: 5px;
    padding-bottom: 5px;

    &.bg-grey-lightest {
      background: transparentize($grey-light-color, 0.6) !important;
      color: transparentize($dark-color, 0.6) !important;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .mat-header-row {
    height: auto;
  }

  .mat-header-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    background: $grey-light-color;
    color: $dark-color;
    opacity: 0.4;
    font-family: $font-family-body;
    border: none;
    font-weight: $fw-bold;

    &, * {
      text-transform: uppercase;
      font-size: 0.7rem;
    }
  }

  .mat-card & {
    display: block;
    margin: 0 (-$mat-card-padding);

    .table-outer-wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {

      &:first-child {
        padding-left: $mat-card-padding;
      }
    }

    .no-table-data {
      padding-left: $mat-card-padding;
    }
  }
}
