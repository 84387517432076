@import "../../imports/imports";

.loader-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &.overlay {
    left: 0;
    top: 0;
    position: absolute;
    background: transparentize($light-color, 0.1);
    z-index: 10;

    .main-loader & {
      z-index: 1000;
    }
  }

  .mat-progress-spinner {
    circle {
      stroke-width: 2% !important;

      .mat-icon-button &, .mat-flat-button &, .mat-button & {
        stroke: $light-color;
      }

      @include embedded-color-scheme(stroke);
    }
  }

  .small-loader & {
    .mat-progress-spinner {
      margin-top: 50px;
      transform: scale(0.5);
    }
  }

  &.sm {
    overflow: hidden;
    width: 32px;
    height: 32px;

    .mat-progress-spinner {
      circle {
        stroke-width: 6% !important;
      }
    }

    .loader {
      transform: scale(0.2);
    }
  }
}

content-loader {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
}
